<template>
  <el-card class="floor-wrapper" shadow="hover">
    <el-row class="site-floor-wrap" :gutter="20">
      <el-col class="site-realistic-picture" :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
        <el-image :src="src" fit="fill"></el-image>
        <!-- 图片显示模式 scale-down -->
      </el-col>
      <el-col class="site-information-wrap" :xs="24" :sm="12" :md="12" :lg="7" :xl="7">
        <div class="info-floor-wrap essential-info">
          <strong> 站点名称: {{ siteInfo.siteName }} </strong>
          <el-link type="primary" @click="$router.push({ name: 'AddSite', query: { stId: siteInfo.siteID } })">更多详情>></el-link>
        </div>

        <div class="info-floor-wrap">
          <strong> 站点编号:{{ siteInfo.siteID }} </strong>
          <span> 站点模式: {{ siteInfo.siteMode | interpretSiteModel }} </span>
        </div>
        <div class="info-floor-wrap">
          <strong>
            站点地址：
            <el-link type="primary" @click="showSiteAddress(site)">
              <i class="el-icon-view el-icon--right"></i>
              {{ siteInfo.siteAddress ? siteInfo.siteAddress : '未知' }}
            </el-link>
          </strong>
        </div>
        <div class="info-floor-wrap">最后更新时间：{{ updateTime }}</div>
      </el-col>
      <el-col class="site-state-information-wrap" :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
        <div class="site-state-information">
          <div class="state-table-tr">
            <div class="table-td table-label">实时状态</div>
            <div class="table-td state-item sub-state">
              <Spot :state="site.subState" />
            </div>
          </div>
          <div class="state-table-tr">
            <div class="table-td table-label">设备状态</div>
            <div class="table-td state-item">
              无人机
              <SpotOnline :state="site.isUAVOnline" />
            </div>
            <div class="table-td state-item">
              天枢
              <SpotOnline :state="site.isSCOnline" />
            </div>
            <div class="table-td state-item" v-if="siteInfo.siteMode == 1">
              机库
              <SpotOnline :state="site.hiveState" />
            </div>
          </div>
          <div class="state-table-tr" v-if="siteInfo.siteMode == 1">
            <div class="table-td table-label">气象状态</div>
            <div class="table-td state-item">天气: {{ site.isRaining ? '有雨' : '无雨' }}</div>
            <div class="table-td state-item">风速: {{ (site.windSpeed && site.windSpeed.toFixed(1)) || 0 }} m/s</div>
            <div class="table-td state-item">风向:{{ windDirectionShow }}</div>
          </div>
        </div>
      </el-col>
      <el-col class="site-access-handle-wrap access-handle" :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
        <div class="btn-group">
          <el-button type="success" size="mini" @click="$emit('handleInit', siteInfo)">操 作 中 心</el-button>
          <el-button v-if="allowSchedule" type="primary" size="mini" @click="$router.push({ path: '/timer', query: { siteID: siteInfo.siteID } })">计 划 任 务</el-button>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import Spot from '@/components/Spot';
import SpotOnline from '@/components/SpotOnline';
import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE';
export default {
  props: {
    siteInfo: {
      type: Object,
      default: {},
    },
    socketInstance: {
      type: Object,
      default: {},
    },
  },
  components: {
    Spot,
    SpotOnline,
  },
  data() {
    return {
      rander: false,
      site: {
        isUAVOnline: 0,
        isSCOnline: 0,
        subState: 0,
        droneUpdate: new Date().getTime() + 5000,
        hiveState: 0,
        windSpeed: 0,
        isRaining: 0,
        hiveUpdate: new Date().getTime() + 5000,
      },
      windDirection: null,
      dev: false,
      visible: false,
      dialogVisible: true,
      heartBeatingTimer: null,
    };
  },

  computed: {
    src() {
      if (this?.siteInfo?.hiveinfo?.hiveModel) {
        let model = this.siteInfo.hiveinfo.hiveModel;
        if (model === SITE_HIVE_TYPE.HIVEMODEL.UH_MK3) {
          return require('@/assets/images/map/site/real/UH_MK3.png');
        } else if (model === SITE_HIVE_TYPE.HIVEMODEL.UH_MK4) {
          return require('@/assets/images/map/site/real/UH_MK4.png');
        } else if (model === SITE_HIVE_TYPE.HIVEMODEL.UH_MK4_Pro) {
          return require('@/assets/images/map/site/real/UH_MK4_Pro.png');
        } else if (model === SITE_HIVE_TYPE.HIVEMODEL.UH_MK5) {
          return require('@/assets/images/map/site/real/UH_MK5.png');
        } else if (model === SITE_HIVE_TYPE.HIVEMODEL.MH_MK3) {
          return require('@/assets/images/map/site/real/MH_MK3.png');
        } else if (model === SITE_HIVE_TYPE.HIVEMODEL.WK_YU_JK) {
          return require('@/assets/images/map/site/real/WK_YU_JK.png');
        }
      }
      return require('@/assets/images/LoginBG/img2.png');
    },
    windDirectionShow() {
      if (this.windDirection != null) {
        let i = this.windDirection;
        let text = '';
        i = parseInt(i);
        if (i > 0 && i < 90) {
          text = `北偏东${i}°`;
        } else if (i == 90) {
          text = '正东';
        } else if (i > 90 && i < 180) {
          text = `西偏南${180 - i}°`;
        } else if (i == 180) {
          text = '正南';
        } else if (i > 180 && i < 270) {
          text = `南偏西${360 - i}°`;
        } else if (i == 270) {
          text = '正西';
        } else if (i > 270 && i < 360) {
          text = `西偏北${360 - i}°`;
        } else if (i == 360 || i == 0) {
          text = '正北';
        }
        return text;
      }
      return '未知';
    },
    allowSchedule() {
      if (!this.$store.state.user.permission.includes('add')) {
        return false;
      }
      if (this.siteInfo && this.siteInfo.siteMode == SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
        return true;
      }
      return false;
    },
    updateTime() {
      let time = this.site.updateTime ? this.site.updateTime : this.site.createTime;
      return this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  created() {},

  mounted() {
    this.unlockSocket(this.siteInfo);
    this.getRoles();
  },
  methods: {
    getRoles() {
      // let u = getUserInfomation()
    },
    handleEdit(site) {
      this.$emit('handleEdit', site);
    },
    handleDelete(site) {
      this.visible = false;
      this.$emit('handleDelete', site);
    },
    handleDetail(id) {
      this.$emit('handleDetail', id);
    },
    reference() {
      this.visible = false;
    },
    //显示站点位置
    showSiteAddress(site) {
      this.$emit('pos', site);
    },
    heartBeating() {
      this.heartBeatingTimer = setInterval(() => {
        let time = new Date().getTime();
        const { droneUpdate, hiveUpdate } = this.site;
        if (!droneUpdate && !hiveUpdate) {
          this.site.uavState = 0;
          this.site.isSCOnline = 0;
          this.site.subState = 0;
          this.site.hiveState = 0;
          this.site.weather = 0;
        } else if (droneUpdate && droneUpdate < time) {
          this.site.uavState = 0;
          this.site.isSCOnline = 0;
          this.site.subState = 0;
        } else if (hiveUpdate && hiveUpdate < time) {
          this.site.hiveState = 0;
          this.site.weather = 0;
        }
      }, 30000);
    },
    unlockSocket(site) {
      this.socketInstance?.on(site.siteID + ' state', (data) => {
        //开启主题监听
        const { type } = data;
        if (type === 1) {
          this.site.isUAVOnline = data.isUAVOnline ? 1 : 0;
          this.site.isSCOnline = data.isSCOnline;
          this.site.subState = data.subState;
          this.site.droneUpdate = new Date().getTime() + 5000;
        } else {
          this.site.hiveState = 1;
          this.site.windSpeed = data.windSpeed;
          this.site.isRaining = data.isRaining;
          this.site.hiveUpdate = new Date().getTime() + 5000;
          this.windDirection = data.windDirection;
        }
      });
      this.heartBeating();
    },
  },
  beforeDestroy() {
    clearInterval(this.heartBeatingTimer);
    this.heartBeatingTimer = null;
  },
};
</script>

<style lang="scss" scoped>
.floor-wrapper {
  background: var(--el-fill-color-blank) !important;
  margin-bottom: 10px;
  border-color: var(--main-border-color);
  .site-floor-wrap {
    color: var(--color-primary);
    height: auto;
    > div {
      height: 100%;

      display: flex;
      flex-direction: column;
      > div {
        width: 100%;
        margin: auto;
        span {
          white-space: nowrap;
        }
      }
    }
    .site-realistic-picture {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .el-image {
        height: 122px;
        // min-width: 200px;
      }
    }
    .site-information-wrap {
      .info-floor-wrap {
        padding: 4px;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        &.essential-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .site-state-information-wrap {
      min-width: 280px;
      font-size: 14px;
      .site-state-information {
        border: 1px solid #{$blue};
      }
      .state-table-tr {
        height: 40px;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #{$blue};
        .table-td {
          width: auto;
          flex: 1;
          min-width: 70px;
          margin: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #{$blue};
          &.table-label {
            // background: #e60039;
          }
          &.table-td:nth-child(1) {
            border: none;
          }
          &.sub-state {
            width: 75%;
          }
        }
        &.state-table-tr:nth-last-child(1) {
          border: none;
        }
      }
    }
  }
  ::v-deep .el-card__body {
    .el-row {
      // height: 100%;
      .site-infomation-wrapper {
        .site-name-more {
          padding: 10px 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-sizing: border-box;
        }
        .site-base-infor-wrap {
          padding: 10px;
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
        }
      }
      .site-state-infomation-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .state-table {
          border-top: 1px solid #{$blue};
          border-left: 1px solid #{$blue};
          background: #{$containerBg};
          color: var(--color-primary);
          td {
            height: 48px;
            width: 160px;
            border-bottom: 1px solid #{$blue};
            border-right: 1px solid #{$blue};
            font-size: 14px;
            > span {
              .icon-fenxi {
                font-size: 30px;
                cursor: pointer;
              }
              .icon-fenxi:hover {
                color: var(--el-color-primary);
              }
            }
            > div {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .access-handle {
        // height: 146px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        .btn-reference {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 0;
          right: 10px;
        }
        .btn-group {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 30px;
          .el-button {
            width: 100%;
            border-radius: 0;
            margin-bottom: 10px;
          }
          .el-button:nth-last-child(1) {
            margin: 0;
          }
        }
        i {
          position: absolute;
          top: 0;
          right: 10px;
          color: var(--color-primary);
          background: rgba(125, 125, 125, 0.5);
          font-size: 24px;
          cursor: pointer;
          &:hover {
            background: #e60039;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .el-col-sm-24 {
    width: 100%;
  }
}
</style>

<template>
  <div class="spot-wrap">
    <a class="large button" :class="state | classNameFilter"></a>
    {{ state | stateInterpret }}
  </div>
</template>

<script>
export default {
  name: 'Spot',
  props: {
    state: {
      type: Number,
      default: 0
    }
  },
}
</script>

<style lang="scss" scoped>
.spot-wrap {
  height: 30px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  a.button {
    display: inline-block;
    width: 12px;
    height: 12px;
    padding: 0px;
    margin: 0px;
    border-radius: 50%;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-right: 10px;
  }
  .green.button {
    animation-name: greenPulse;
    animation-duration: 3s;
  }
  .white.button {
    animation-name: whitePulse;
    animation-duration: 3s;
  }
  .origin.button {
    animation-name: originPulse;
    animation-duration: 3s;
  }
  .blue.button {
    animation-name: bluePulse;
    animation-duration: 3s;
  }
}
@keyframes greenPulse {
  from {
    background-color: #5ee443;
    -webkit-box-shadow: 0 0 9px rgba(90, 247, 70, 0.665);
  }

  50% {
    background-color: #00b30f;
    -webkit-box-shadow: 0 0 9px #49ff01c9;
  }

  to {
    background-color: #5ee443;
    -webkit-box-shadow: 0 0 9px rgba(90, 247, 70, 0.665);
  }
}
@keyframes whitePulse {
  from {
    background-color: #eef1ec;
    -webkit-box-shadow: 0 0 9px rgba(243, 247, 242, 0.665);
  }

  50% {
    background-color: #d2d6d3;
    -webkit-box-shadow: 0 0 9px #e9e9e9c9;
  }

  to {
    background-color: #eef1ec;
    -webkit-box-shadow: 0 0 9px rgba(225, 228, 225, 0.665);
  }
}
@keyframes originPulse {
  from {
    background-color: rgba(255, 126, 54, 0.665);
    -webkit-box-shadow: 0 0 9px rgba(255, 126, 54, 0.665);
  }

  50% {
    background-color: rgb(255, 92, 0);
    -webkit-box-shadow: 0 0 9px rgb(255, 92, 0);
  }

  to {
    background-color: rgba(255, 126, 54, 0.665);
    -webkit-box-shadow: 0 0 9px rgba(255, 126, 54, 0.665);
  }
}
@keyframes bluePulse {
  from {
    background-color: rgba(77, 171, 247, 0.665);
    -webkit-box-shadow: 0 0 9px rgba(77, 171, 247, 0.665);
  }

  50% {
    background-color: rgb(10, 129, 227);
    -webkit-box-shadow: 0 0 9px rgb(10, 129, 227);
  }

  to {
    background-color: rgba(77, 171, 247, 0.665);
    -webkit-box-shadow: 0 0 9px rgba(77, 171, 247, 0.665);
  }
}
</style>
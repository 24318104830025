<template>
    <el-form class="global-search-bar-wrapper" :inline="true" size="small">
        <el-form-item :label="uLabel">
            <el-input v-model="searchInfo.inputValue"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSearch(searchInfo.inputValue)">搜索</el-button>
        </el-form-item>
        <el-form-item style="float:right" class="renovate-botton-wrapper">
            <el-button type="primary" @click="onRenovate">刷新</el-button>
        </el-form-item>
        <el-form-item style="float:right">
            <el-button type="primary" @click="onAccretion">新增</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: 'GlobalSearchBar',
    props: {
        uLabel: {
            type: String,
            default() {
                return ""
            }
        }
    },
    data() {
        return {
            searchInfo: {
                inputValue: '',
            }
        }
    },
    methods: {
        onSearch(value) {
            this.$emit('onSearch', value)
        },
        onRenovate() {
            this.$emit('onRenovate')
        },
        onAccretion() {
            this.$emit('onAccretion')
        },
    }
}
</script>

<style lang="scss" scoped>
.global-search-bar-wrapper {
    ::v-deep .el-form-item {
        margin-bottom: 0;
        .el-form-item__label {
            line-height: 34px;
        }
        .el-input {
            input {
                height: 34px;
            }
        }
        .el-button {
            border: none;
        }
    }
    .renovate-botton-wrapper {
        margin-right: 0;
    }
}
</style>
<template>
  <el-card class="site-page-wrapper page-container">
    <search-bar slot="header" @pId="handleSearchSites" @siteName="handleSearchSitesBySiteName"></search-bar>
    <div class="site-content-list">
      <site-floor v-for="site in siteData" :key="site.siteID + new Date().getTime()" ref="siteGroup" :siteInfo="site" :socketInstance="socketInstance" @handleInit="handleInit" @handleDelete="handleDelete" @handleDetail="handleDetail" @pos="posHandler"></site-floor>
    </div>
    <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
      <span>{{ siteDetail }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="位置" custom-class="custom-class" :visible.sync="mapDialogVisible" width="600px" :before-close="handleMapClose">
      <div class="map-content">
        <ol-map ref="map" @postrender="mapRendered"></ol-map>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import OlMap from '@/components/maps/OlMap';
import SearchBar from './component/SearchBar';
import SiteFloor from './component/SiteFloor';
import GlobalSearch from '@/components/SearchBar';
import socket from '@/utils/socket';
import { getSiteGroup, getSiteDetail, deleteSiteByStid } from '@/api/site';
import statusInclude from '@/utils/statusCode';
export default {
  components: {
    OlMap,
    SearchBar,
    SiteFloor,
    GlobalSearch,
  },
  data() {
    return {
      tHeight: 0,
      map: null,
      PID: '',
      siteLocationMap: null,
      currentPage: 1,
      totalNum: 0,
      pageSize: 13,
      socketInstance: null,
      dialogVisible: false,
      mapDialogVisible: false,
      SchedulingDialogVisible: false,
      expand: {
        uavState: 0,
        osdkState: 0,
        subState: 0,
        droneUpdate: 0,
        hiveState: 0,
        weather: 0,
        windSpeed: 0,
        isRaining: 0,
        hiveUpdate: 0,
      },
      siteData: [],
      siteDetail: {},
      randomHash: '*sda',
    };
  },
  watch: {
    siteData: {
      handler(newSite, oldSite) {
        this.hashCode('hash');
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
    this.socketInstance = socket.initSocket(); //创建socket连接
    this.getSiteListData().then((siteData) => {
      if (siteData === '请求站点信息失败') return this.$message({ type: 'warning', message: siteData });
      this.siteData = siteData.map((e) => Object.assign(e, this.expand));
    });
  },
  methods: {
    hashCode(str) {
      var h = 0;
      var len = str.length;
      var t = 2147483648;
      for (var i = 0; i < len; i++) {
        h = 31 * h + str.charCodeAt(i);
        if (h > 2147483647) h %= t; //java int溢出则取模
      }
      /*var t = -2147483648 * 2;
     while (h > 2147483647) {
     h += t
     }*/
      return h;
    },

    /**
     * @description 获取窗口高度
     * @return void
     */
    getHeight() {
      this.tHeight = window.innerHeight - 223;
    },

    handleSearchSites(PID) {
      this.PID = PID;
      this.getSiteListData({ PID }).then((siteData) => {
        if (siteData === '请求站点信息失败') return this.$message({ type: 'warning', message: siteData });
        this.siteData = siteData.map((e) => Object.assign(e, this.expand));
      });
    },

    handleSearchSitesBySiteName(name) {
      this.siteData = [];
      if (this.PID) {
        this.getSiteListData({ PID: this.PID }).then((siteData) => {
          if (siteData === '请求站点信息失败') return this.$message({ type: 'warning', message: siteData });
          if (name) {
            this.siteData = siteData.filter((e) => {
              return e.siteName.indexOf(name) > -1;
            });
          } else {
            this.siteData = siteData.map((e) => Object.assign(e, this.expand));
          }
        });
      } else {
        this.getSiteListData().then((siteData) => {
          if (siteData === '请求站点信息失败') return this.$message({ type: 'warning', message: siteData });
          if (name) {
            this.siteData = siteData.filter((e) => {
              return e.siteName.indexOf(name) > -1;
            });
          } else {
            this.siteData = siteData.map((e) => Object.assign(e, this.expand));
          }
        });
      }
    },

    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    /**
     * @description 进入操作中心
     * @param site 站点信息
     * @return void
     */
    handleInit(site) {
      this.$router.push({ path: '/handleCenter', query: { siteID: site.siteID } });
    },

    handleDelete(site) {
      deleteSiteByStid(site.siteID).then((res) => {
        if (statusInclude(res.code)) {
          this.$message({ type: 'success', message: '删除成功' });
          this.getSiteListData().then((siteData) => {
            if (siteData === '请求站点信息失败') return this.$message({ type: 'warning', message: siteData });
            this.siteData = siteData.map((e) => Object.assign(e, this.expand));
          });
        }
      });
    },

    handleDetail(id) {
      this.getSiteDetail(id);
    },

    handleClose() {},
    handleMapClose() {
      this.mapDialogVisible = false;
    },
    mapRendered() {
      this.map = this.$refs.map;
      if (this.siteLocationMap) {
        this.map.addHiveMarker(this.siteLocationMap[0], this.siteLocationMap[1], true);
      }
    },
    posHandler(site) {
      let hiveModel = site?.hiveinfo?.hiveModel;
      hiveModel = hiveModel ? hiveModel : null;
      this.mapDialogVisible = true;
      if (this.map) {
        this.map.addHiveMarker(site.siteModel, hiveModel, site.siteLocation[0], site.siteLocation[1], true);
      } else {
        setTimeout(() => {
          if (this.map) {
            this.map.addHiveMarker(site.siteModel, hiveModel, site.siteLocation[0], site.siteLocation[1], true);
          }
        }, 1000);
      }
    },

    async getSiteListData({ page = 1, size = 10000, PID } = {}) {
      return new Promise(async (resolve, reject) => {
        const { code, data, reason } = await getSiteGroup(page, size, PID);
        if (statusInclude(code)) {
          const { total, list } = data;
          const compare = function (property) {
            return function (a, b) {
              const v1 = a[property].replace(/[^0-9]/gi, '');
              const v2 = b[property].replace(/[^0-9]/gi, '');
              return v1 - v2;
            };
          };
          resolve(list.sort(compare('siteID')));
        } else {
          reject(new Error(`请求站点信息失败${reason}`));
        }
      });
    },
    async getSiteDetail(id) {
      const { code, data, reason } = await getSiteDetail(id);
      this.siteDetail = data;
      this.dialogVisible = true;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight);
    if (this.socketInstance && this.socketInstance !== null) {
      socket.clearSocket();
      this.socketInstance = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.map-content {
  width: 100%;
  height: 600px;
  position: relative;
}

.custom-class {
  padding: 0;
}

.site-page-wrapper {
  background: #{$cardBg};

  ::v-deep .el-card__header {
    background: #{$cardHeaderBg};
  }

  ::v-deep .site-content-list {
    height: calc(100vh - 225px);
    position: relative;
    overflow: auto;

    .floor-wrapper:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .site-content-list::-webkit-scrollbar {
    width: 0;
  }

  .el-pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px;
  }
}
</style>
